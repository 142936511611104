import React from "react";
import Layout from "../components/layout";
import Text from "../components/SVG/TextDivider";
import { Link } from "gatsby";

const NotFoundPage = () => {
  const browser = typeof window !== "undefined" && window;
  return (
    browser && (
      <Layout title="404: Not found">
        <div style={{ minHeight: "400px" }}>
          <Text containerStyle={{ marginTop: "3rem" }}>404: Not Found</Text>
          <h4
            style={{ color: "black", textAlign: "center", padding: `0 5rem` }}
          >
            Since you&#39;re here. Why not check out some of our best products?
          </h4>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "5rem",
            }}
          >
            <Link to="/#BestSeller" className="primary-btn">
              Lets go!
            </Link>
          </div>
        </div>
      </Layout>
    )
  );
};

export default NotFoundPage;
